import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';


@Directive({
    selector: '[appBirthDayNumberValidator]',
    providers: [{ provide: NG_VALIDATORS, useExisting: BirthDayNumberValidatorDirective, multi: true }],
    standalone: true
})
export class BirthDayNumberValidatorDirective implements Validator {

  // This method will be called by Angular forms to perform validation.
  validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value; // Extract the current value of the control.

    // If the control value is falsy (e.g., empty), consider it valid as this validator
    // does not check for required values.
    if (!value) {
      return null; // Consider empty value as valid.
    }

    // Check if the value is numeric and its length is exactly 6 or 11.
    // This ensures the input matches the expected format for validation.
    if (/^\d+$/.test(value) && (value.length === 6 || value.length === 11)) {
      // If the value passes the initial format check, validate the date.
      // This part extracts the first 6 digits to validate them as a date in ddmmyy format.
      if (!this.isValidDate(value.substr(0, 2), value.substr(2, 2), value.substr(4, 2))) {
        // If the date is not valid, return a validation error.
        return { 'invalidDateFormat': true };
      }
      // If the date is valid, return null indicating the value is valid.
      return null;
    } else if (/^\d+$/.test(value)) {
      // If the value is numeric but does not meet the length requirements,
      // return a validation error for invalid length.
      return { 'invalidLength': true };
    }

    // If the value is not numeric, return a validation error for invalid number format.
    return { 'invalidNumberFormat': true };
  }

  // Helper function to validate the date.
  private isValidDate(dd: string, mm: string, yy: string): boolean {
    // Parse the day, month, and year from the input.
    const day = parseInt(dd, 10);
    const month = parseInt(mm, 10) - 1; // JavaScript months are 0-based.
    const year = parseInt(yy, 10) + 2000; // Assuming yy represents a year in the 2000s.

    // Check for invalid month.
    if (month < 0 || month > 11) {
      return false; // Invalid month makes the date invalid.
    }

    // Create a Date object with the parsed values.
    const date = new Date(year, month, day);
    // Check if the generated Date object matches the input values.
    // This comparison validates the day, especially for handling cases like February 29.
    if (date.getFullYear() !== year || date.getMonth() !== month || date.getDate() !== day) {
      return false; // If there's a mismatch, the date is invalid.
    }

    // If all checks pass, the date is valid.
    return true;
  }

}
