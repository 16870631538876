export enum PhoneNumberTypeIdEnum {
    Work = "21321311-0000-0000-0000-000000000037",
    Fax = "21321311-0000-0000-0000-000000000038",
    Mobile = "21321311-0000-0000-0000-000000000039",
    Vacation = "21321311-0000-0000-0000-000000000040",
    Div = "21321311-0000-0000-0000-000000000041",
    Private = "21321311-0000-0000-0000-000000000042"
}




export const PhoneNumberTypes = {
    Work: { Id: "21321311-0000-0000-0000-000000000037", Name: "Telefon arbeid"},
    Fax: { Id: "21321311-0000-0000-0000-000000000038", Name: "Faks"},
    Mobile: { Id: "21321311-0000-0000-0000-000000000039", Name: "Mobil"},
    Vacation: { Id: "21321311-0000-0000-0000-000000000040", Name: "Telefon ferie"},
    Div: { Id: "21321311-0000-0000-0000-000000000041", Name: "Telefon diverse"},
    Private: { Id: "21321311-0000-0000-0000-000000000042", Name: "Telefon privat"}
}

export const PhoneNumberTypesArray = [
    { Id: "21321311-0000-0000-0000-000000000037", Name: "Telefon arbeid"},
    { Id: "21321311-0000-0000-0000-000000000038", Name: "Faks"},
    { Id: "21321311-0000-0000-0000-000000000039", Name: "Mobil"},
    { Id: "21321311-0000-0000-0000-000000000040", Name: "Telefon ferie"},
    { Id: "21321311-0000-0000-0000-000000000041", Name: "Telefon diverse"},
    { Id: "21321311-0000-0000-0000-000000000042", Name: "Telefon privat"}
]