<!-- <dlx-textarea [(ngModel)]="address" autocomplete="off" [label]="addressType.Name + ':'"
    [name]="'address_' + addressType.Name" [rows]="3" [disabled]="locked">

</dlx-textarea> -->
<div class="address-wrapper flex-gap-18">
  <div class="div1">
    <igx-input-group displayDensity="compact" type="border">
      <textarea igxInput [name]="'address_' + randomId" autocomplete="off" type="text" rows="3" [(ngModel)]="address"
        style="resize: none;margin-bottom: 18px;" [disabled]="!hasEditRights"></textarea>
      <label igxLabel>{{addressType.Name + ':'}}</label>
    </igx-input-group>
  </div>
  <i *ngIf="lockable && locked && screen.width < 550" class="material-icons dlx-input-lock-icon">lock</i>

  @if(showZipCode) {
  <div class="div2">
    <igx-input-group class="zipcode-input" displayDensity="compact" type="border">
      <input #zipcode igxInput name="zipCode" type="text" [(ngModel)]="zipCode" (focus)="onZipCodeFocus()"
        (input)="validateZipCode(zipcode)" (blur)="onZipCodeBlur()" autocomplete="off" [disabled]="!hasEditRights" />
      <label igxLabel for="zipCode">Postnr:</label>
      <igx-suffix class="material-icons" igxIcon (click)="onShowDialog()">search</igx-suffix>
    </igx-input-group>
  </div>
  <div class="div3">
    <igx-input-group class="postArea-input" displayDensity="compact" type="border">
      <input igxInput [name]="'postArea_' + randomId" type="text" [(ngModel)]="postArea" autocomplete="off"
        [disabled]="!hasEditRights" />
      <label igxLabel [for]="'postArea_' + randomId">Poststed:</label>
    </igx-input-group>
  </div>
  }


  <div class="div4 simple-select-wrapper simple-select-disable-reset">
    <label #countryLabel igxLabel class="simple-select-label simple-select-label--selected">Land:</label>
    <igx-simple-combo [data]="countriesDS" displayDensity="compact" type="border"
      (selectionChanging)="onCountryChange($event)" (opening)="combo.comboOpening(countryLabel)"
      (closing)="combo.comboClosing(countryLabel, countryCode)" displayKey="Name" valueKey="Code"
      [(ngModel)]="countryCode" [disabled]="!hasEditRights">
    </igx-simple-combo>
  </div>


  <igx-dialog #zipCodeDialog [closeOnOutsideSelect]="true">
    <div igxDialogTitle>
      <div class="dialog-title-container">
        <div class="dialog-title">Finn postnummer:</div>
        <i class="material-symbols-outlined close-icon" (click)="zipCodeDialog.close()">close</i>
      </div>
    </div>
    <div class="spaceless_dialog_body">
      @if (showDialog) {
      <!-- <app-zip-code-search (notifyZipCodeSelection)="onZipcodeSelection($event)" [pickerCountryCode]="country.Code" /> -->
      <app-zip-code-search (notifyZipCodeSelection)="onZipcodeSelection($event)"
        [pickerCountryCode]="countryCode ?? 'NO'" />
      }
    </div>
  </igx-dialog>
</div>