import { Component, OnInit, Input, Output, EventEmitter, Inject, OnDestroy, inject, ViewChild } from '@angular/core';
import { IgxColumnComponent, IgxGridComponent, GridColumnDataType, IgxNumberFilteringOperand, IgxStringFilteringOperand, IRowSelectionEventArgs, IgxOverlayService, OverlayEventArgs, IgxInputGroupComponent, IgxInputDirective, IgxLabelDirective, IgxSuffixDirective, IgxSimpleComboComponent, IgxDialogComponent, IgxDialogTitleDirective, ISimpleComboSelectionChangingEventArgs } from '@infragistics/igniteui-angular';

import { DatalexClient, IContactAddressBE, ICountryBE, IPostalAddressBE, UserAreaEnum, UserRightTypeEnum } from '@datalex-software-as/datalex-client';
import { CountryService, ICountry } from 'src/app/services/country.service';
import { ScreenDimensionService } from 'src/app/services/screen-dimension.service';

import { EmptyGuid, RandomId } from 'src/app/util/RandomId';
import { ContactDataService } from '../../pages/contact/contact-data.service';
import { ZipCodeSearchComponent } from '../zip-code-search/zip-code-search.component';
import { GridMethodsService } from 'src/app/services/grid-methods.service';
import { ComboSelectFunctionsService } from 'src/app/services/combo-select-functions.service';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SystemCacheService } from 'src/app/services/system-cache.service';
import { UserRightsProviderService } from 'src/app/services/user-rights-provider.service';

@Component({
  selector: 'dlx-address-input',
  templateUrl: './dlx-address-input.component.html',
  styleUrls: ['./dlx-address-input.component.scss'],
  standalone: true,
  imports: [IgxInputGroupComponent, FormsModule, IgxInputDirective, IgxLabelDirective, NgIf, IgxSuffixDirective, IgxSimpleComboComponent, IgxDialogComponent, IgxDialogTitleDirective, ZipCodeSearchComponent]
})
export class DlxAddressInputComponent implements OnInit {

  hasEditRights: boolean = false;
  private sys = inject(SystemCacheService);

  constructor(
    private dlxClient: DatalexClient,
    public screen: ScreenDimensionService,
    public gms: GridMethodsService,
    private countryService: CountryService,
    private cData: ContactDataService,
    public combo: ComboSelectFunctionsService,
    private userRights: UserRightsProviderService,
  ) {

  }


  randomId = RandomId();

  @Input() country!: ICountryBE;
  @Input() addressType!: { Id: string, Name: string };
  @Input() lockable: boolean = false;
  @Input() locked: boolean = false;


  _existingAddress!: IContactAddressBE | null;
  @Input() set existingAddress(value: IContactAddressBE | null) {
    this._existingAddress = value;
    if (value) {
      this.contactAddress = value
      this.address = value.Address;
      this.zipCode = value.PostalAddress ? value.PostalAddress.PostCode : null;
      this.postArea = value.PostalAddress ? value.PostalAddress.PostOffice : null;

      //this.setCountryCode();
    } else {
      this.contactAddress = this.createEmptyAddress();
    }

  }
  get existingAddress() {
    return this._existingAddress;
  }

  @Input() countryCode: string | null = "NO";

  @Input() contactCountry!: string | null;
  // @Input() countriesDS: ICountryBE[] = this.cData.countries;
  countriesDS: ICountryBE[] = [];

  @Output() notifyAddressChange: EventEmitter<IContactAddressBE> = new EventEmitter();

  @ViewChild('zipCodeDialog') dialog!: IgxDialogComponent




  _zipCode!: string | null;
  _postArea!: string | null;


  set zipCode(val: string | null) {
    if (val !== null) {
      this._zipCode = String(val.replace(/\D/g, ''));
    } else {
      this._zipCode = val;
    }

    if (val === "") {
      this.contactAddress.PostalAddress = null;
      this.contactAddress.PostalId = null;
      this.postArea = null;
      this.notifyAddressChange.emit(this.contactAddress);
    }

  }

  get zipCode() { return this._zipCode }

  set postArea(val: string | null) {
    this._postArea = val;

    if (val === null) {
      this.contactAddress.PostalAddress = null;
      this.contactAddress.PostalId = null;
      this.zipCode = null;
      this.notifyAddressChange.emit(this.contactAddress);
    }
  }
  get postArea() { return this._postArea }

  _address!: string;

  set address(val: string) {
    this._address = val;
    this.contactAddress.Address = val
    if (this.contactAddress.AddressTypeId === "") {
      this.contactAddress.AddressTypeId = this.addressType.Id;
    }
    this.notifyAddressChange.emit(this.contactAddress);
  };
  get address() { return this._address }


  postalAddress!: IPostalAddressBE
  contactAddress!: IContactAddressBE;


  ngOnInit(): void {

    this.countriesDS = this.countryService.allCountries;

    if (!this.country) {
      this.country = this.countryService.norway.country;
    }
    if (this.existingAddress) {
      this.contactAddress = this.existingAddress
      this.address = this.existingAddress.Address;
      this.zipCode = this.existingAddress.PostalAddress ? this.existingAddress.PostalAddress.PostCode : null;
      this.postArea = this.existingAddress.PostalAddress ? this.existingAddress.PostalAddress.PostOffice : null;
      this.countryCode = this.existingAddress.PostalAddress ? this.existingAddress.PostalAddress.CountryCode : this.setCountryCodeFromAddress();
    } else {
      this.contactAddress = this.createEmptyAddress();
    }
    try {
      this.hasEditRights = this.userRights.checkAccess(UserAreaEnum.CONTACTS).accessId.toUpperCase() === UserRightTypeEnum.FULL.toUpperCase();

    } catch (error) {
      this.sys.isReady.subscribe(() => {
        this.hasEditRights = this.userRights.checkAccess(UserAreaEnum.CONTACTS).accessId.toUpperCase() === UserRightTypeEnum.FULL.toUpperCase();;
      })
    }
  }

  validateZipCode(input: HTMLInputElement) {
    input.value = String(input.value.replace(/\D/g, ''));
  }

  setCountryCodeFromAddress() {
    let foundCountry: ICountryBE | undefined;

    this.address.split(',').forEach((part) => {
      const found = this.countryService.allCountries.find(c => c.Name.toUpperCase().trim() === part.toUpperCase().trim());
      if (found) foundCountry = found;

    })
    this.showZipCode = false;
    return foundCountry ? foundCountry.Code : null;
  }


  showDialog: boolean = false;

  // onShowDialog() {
  //   this.dialog.open();
  //   this.showDialog = true;

  //   this.dialog.closed.subscribe({
  //     next: () => {
  //       this.showDialog = false;
  //     }
  //   });
  // }

  onShowDialog() {
    if (this.zipCode && !this.locked) {
      this.dlxClient.FindPostalAddresses(this.zipCode, this.country.Id).subscribe({
        next: (addresses) => {
          if (addresses.length === 0) {
            this.dialog.open();
            this.showDialog = true;

            this.dialog.closed.subscribe({
              next: () => {
                this.showDialog = false;
              }
            });
          } else {
            this.postArea = addresses[0].PostOffice;
            this.contactAddress.PostalAddress = addresses[0];
            this.contactAddress.PostalId = addresses[0].Id;
            this.notifyAddressChange.emit(this.contactAddress);
          }
        },
        error: () => {
          this.dialog.open();
          this.showDialog = true;
        }
      });
    } else {
      this.dialog.open();
      this.showDialog = true;
    }
  }



  onZipcodeSelection(event: IPostalAddressBE) {
    this.dialog.close();
    this.postArea = event.PostOffice;
    this.zipCode = event.PostCode;
    this.countryCode = event.CountryCode;
    this.contactAddress.PostalAddress = event;
    this.contactAddress.PostalId = event.Id
    this.notifyAddressChange.emit(this.contactAddress);
  }



  createEmptyAddress(): IContactAddressBE {
    return {
      IsDeleted: false,
      IsNew: true,
      Id: EmptyGuid,
      AddressTypeId: "",
      AddressType: "",
      ContactId: EmptyGuid,
      Address: "",
      PostalId: null,
      Timestamp: [],
      PostalAddress: {
        IsNew: true,
        IsDeleted: false,
        Id: "",
        PostCode: "",
        PostOffice: "",
        CountryId: "",
        CountryName: "",
        CountryCode: "",
        Province: "",
        Order: 0,
        Timestamp: [],
        Country: this.country,
        Active: true,
        Code: ""
      }
    }
  }
  onZipCodeFocus() {
    document.addEventListener('keydown', this.lookupZipcodeKeyhandler)
  }
  onZipCodeBlur() {
    document.removeEventListener('keydown', this.lookupZipcodeKeyhandler)
  }

  lookupZipcodeKeyhandler = (event: KeyboardEvent) => {
    if (event.key === 'Tab') {
      if (this.zipCode) {
        this.lookupZipcode();
      } else {
        this.onShowDialog()
      }
    }
  }

  lookupZipcode() {
    if (!this.locked) {
      this.dlxClient.FindPostalAddresses(this.zipCode!, this.country.Id).subscribe({
        next: (e) => {
          console.log("FindPostalAddresses", e);


          if (!e[0]) {
            this.onShowDialog()
            return
          }
          this.postArea = !!e[0] ? e[0].PostOffice : 'INGEN TREFF';
          this.contactAddress.PostalAddress = e[0];
          this.contactAddress.PostalId = e[0].Id
          this.notifyAddressChange.emit(this.contactAddress);


        }
      })
    }
  }

  showZipCode: boolean = true;

  onCountryChange(ev: ISimpleComboSelectionChangingEventArgs) {
    const { newSelection } = ev;
    this.country = newSelection;
    this.dlxClient.FindPostalAddresses('', newSelection.Id).subscribe({
      next: (event) => {
        this.showZipCode = event.length > 0;
        if (this.showZipCode === false) {
          this.address = this.setOrReplaceCountryName(this.address, newSelection.Name);
        } else {
          this.address = this.setOrReplaceCountryName(this.address, newSelection.Name, true);
        }

        if (this.contactAddress.PostalAddress) {
          this.contactAddress.PostalAddress.IsDeleted = true;
          this.contactAddress.PostalId = null;
        }
      },
    })

    if (!this.contactAddress.PostalAddress) return;
    this.contactAddress.PostalAddress.Country = newSelection;
    this.contactAddress.PostalAddress.CountryCode = newSelection.Code
    this.contactAddress.PostalAddress.CountryId = newSelection.Id
    this.contactAddress.PostalAddress.CountryName = newSelection.Namef
    this.notifyAddressChange.emit(this.contactAddress);
  }

  setOrReplaceCountryName(address: string, name: string, remove: boolean = false) {
    let _adr = address.split(',');
    const _country = _adr[_adr.length - 1].trim();
    let foundCountry: ICountryBE | undefined;
    let foundIndex: number | undefined;

    _adr.forEach((part, i) => {
      const found = this.countryService.allCountries.find(c => c.Name.toUpperCase().trim() === part.toUpperCase().trim());
      if (found) {
        foundCountry = found;
        foundIndex = i;
      }
    })


    if (foundCountry && foundIndex !== undefined) {
      if (remove) {
        return address.replace(foundCountry.Name, '').replace(/^,\s*|,\s*$/g, "");
      }
      return address.replace(foundCountry.Name, name);
    }


    const found = this.countryService.allCountries.find(c => c.Name.toUpperCase() === _country.toUpperCase());

    if (!found) return address + `, ${name}`;

    return address.replace(found.Name, name);
  }
}
